<template>
  <fieldset class="form-footer-actions" :class="[clickDeleteAction(), setFieldsetClass()]">
    <!--
      The reading order is inverted for better UX so
      when navigating with TAB key the SUBMIT button
      gets focussed first.
    -->
    <wx-btn-standard
      v-if="clickSubmit"
      @click="clickSubmit"
      :disabled="isSubmitButtonDisabled"
      :accesskey="this.submitBtnAccesskey"
      :title="[$t('common.formSubmitHoverTitle') + ' [' + this.submitBtnAccesskey + ']']"
      color="primary"
      class="submit-btn"
    >
      {{ $t("common.save") }}
      <v-icon right>mdi-arrow-right-bold</v-icon>
    </wx-btn-standard>
    <wx-btn-standard
      v-if="!clickCancelAction() && this.showCancel"
      :to="clickCancelReroute()"
      :accesskey="this.cancelBtnAccesskey"
      :title="[$t('common.formCancelHoverTitle') + ' [' + this.cancelBtnAccesskey + ']']"
      color="secondary"
      class="cancel-btn"
    >
      {{ $t("common.cancel") }}
    </wx-btn-standard>
    <wx-btn-standard
      v-if="clickCancelAction()"
      @click="clickCancel"
      :accesskey="this.cancelBtnAccesskey"
      :title="[$t('common.formCancelHoverTitle') + ' [' + this.cancelBtnAccesskey + ']']"
      color="secondary"
      class="cancel-btn"
    >
      {{ $t("common.cancel") }}
    </wx-btn-standard>
    <dialog-confirm-deletion
      v-if="isDeleteFunctionDefined()"
      :as-text-button="true"
      :title="deletionTitle"
      :info-box-text-title="$t('common.attention')"
      :info-box-text="deletionInfoText"
      :confirm-text-prefix="deletionConfirmationPrefix"
      :element-display-name="itemToDeleteDisplayName"
      @confirm="clickDelete"
      activator-btn-color="secondary"
      activator-btn-class="delete-btn"
    />
  </fieldset>
</template>

<script>
import RouteService from "@/router/RouteService";
import WxBtnStandard from "@/components/ui/WxBtnStandard";
import DialogConfirmDeletion from "@/components/DialogConfirmDeletion";

export default {
  name: "FormFooterActions",
  components: {
    WxBtnStandard,
    DialogConfirmDeletion,
  },
  props: {
    fieldsetClass: {
      type: String,
      default: "",
    },
    clickSubmit: {
      type: Function,
      required: true,
    },
    disabledClickSubmit: {
      type: Function,
      default: () => false,
    },
    clickCancel: {
      type: Function,
      default: null,
    },
    showCancel: {
      type: Boolean,
      default: () => true,
    },
    cancelTo: {
      type: String,
      default: "toRoot",
    },
    clickDelete: {
      type: Function,
      default: null,
    },
    deletionTitle: {
      type: String,
      default: "",
    },
    deletionInfoText: {
      type: String,
      default: "",
    },
    deletionConfirmationPrefix: {
      type: String,
      default: "",
    },
    itemToDeleteDisplayName: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      submitBtnAccesskey: "s",
      cancelBtnAccesskey: "z",
      deleteBtnAccesskey: "x",
    };
  },
  computed: {
    isSubmitButtonDisabled() {
      if (this.disabledClickSubmit) {
        return this.disabledClickSubmit();
      }
      return true;
    },
  },
  methods: {
    isDeleteFunctionDefined() {
      return this.clickDelete !== null && this.clickDelete !== undefined;
    },
    clickDeleteAction() {
      return this.clickDelete ? "display-delete-btn" : false;
    },
    clickCancelReroute() {
      return RouteService[this.cancelTo]();
    },
    clickCancelAction() {
      return this.clickCancel;
    },
    clickSubmitAction() {
      return this.clickSubmit;
    },
    setFieldsetClass() {
      return this.fieldsetClass;
    },
  },
};
</script>

<style lang="scss" scoped>
.form-footer-actions {
  display: flex;
  margin-top: 1rem;
  border-color: transparent;
  border-radius: 0;

  ::v-deep .delete-btn {
    order: 3;
  }
  .cancel-btn {
    order: 2;
  }
  .submit-btn {
    order: 1;
  }

  // vertical phone only
  @media (max-width: $wx-max-width-phone-vertical) {
    flex-direction: column;
    padding-top: var(--box-padding-admin);
    border-top: 1px solid var(--color-border-theme);

    .v-btn,
    ::v-deep .v-btn.dialog-activator-btn {
      margin-top: var(--field-vertical-gutter);
    }
  }
  // larger then a vertical phone
  @media (min-width: $wx-min-width-phone-vertical) {
    flex-direction: row-reverse;
    padding-top: var(--field-vertical-gutter);

    .v-btn,
    ::v-deep .v-btn.dialog-activator-btn {
      margin-right: var(--box-padding-admin);

      &:first-child {
        margin-right: 0;
      }
      &.delete-btn {
        margin-right: auto; // align left
      }
    }
  }
}
</style>
